import { getToken, getCookie } from '@/utils/auth'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  username: '',
  useremail: '',
  userid: '',
  roleKey: [],
  deptID: '',
  id: '',
  petName:'',
  nickName: '',
  userIdTime: getCookie('CLOUD-USER-ID'),
  isADAccount: 0,
  adUserName: '',
  packUp: 0, // 全局tips展开/收起 0：展开， 1： 收起
  vipStatus: 0, // VIP状态
  vipDeadLine: '' // VIP到期时间
}

const userState = () => {
  return { ...state }
}

export type UserState = ReturnType<typeof userState>
export default state