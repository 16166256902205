import state, { SidebarState } from './state/sidebar'
import {ActionContext} from 'vuex'

const mutations = {
  // 个人中心侧边栏缩放
  TOGGLE_USER_SIDEBAR: (state:SidebarState) => {
    state.userSidebar.opened = !state.userSidebar.opened
  }
}

const actions = {
  toggleUserSidebar({ commit }:ActionContext<SidebarState, unknown>) {
    commit('TOGGLE_USER_SIDEBAR')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}