import state, { AppState } from './state/app'
import { ActionContext } from 'vuex'
import Cookies from 'js-cookie'


const mutations = {
  TOGGLE_SIDEBAR: (state:AppState) => {
    //点击缩放功能
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', '1')
    } else {
      Cookies.set('sidebarStatus', '0')
    }
  },
  //自适应功能
  CLOSE_SIDEBAR: (state:AppState, withoutAnimation:boolean) => {
    Cookies.set('sidebarStatus', '0')
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state:AppState, device:string) => {
    state.device = device
  },
  SET_SIZE: (state:AppState, size:string) => {
    state.size = size
    Cookies.set('size', size)
  },
  SET_IS_MOBILE: (state:AppState, isMobile:boolean) => {
    state.isMobile = isMobile
  },
  SET_XIN_YUN: (state:AppState, xinYun:boolean) => {
    state.xinYun = xinYun
  },
  SET_TOOL: (state:AppState, tool:boolean) => {
    state.tool = tool
  },
  SET_ANALYSIS: (state:AppState, analysis:boolean) => {
    state.analysis = analysis
  },
  SET_IS_SUPER_ADMIN: (state:AppState, isSuperAdmin:boolean) => {
    state.isSuperAdmin = isSuperAdmin
  },
  SET_REFRNA_BETA: (state:AppState, refrna_beta:boolean) => {
    state.refrna_beta = refrna_beta
  },
  SET_NOTIFY_SHOW: (state:AppState, notifyShow:boolean) => {
    state.notifyShow = notifyShow
  }
}

const actions = {
  toggleSideBar({ commit }:ActionContext<AppState, unknown>) {
    //调用mutations中的方法
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }:ActionContext<AppState, unknown>, { withoutAnimation }:{ withoutAnimation:boolean }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }:ActionContext<AppState, unknown>, device:string) {
    commit('TOGGLE_DEVICE', device)
  },
  setSize({ commit }:ActionContext<AppState, unknown>, size:string) {
    commit('SET_SIZE', size)
  },
  setIsMobile({ commit }:ActionContext<AppState, unknown>, isMobile:boolean) {
    commit('SET_IS_MOBILE', isMobile)
  },
  setXinYun({ commit }:ActionContext<AppState, unknown>, xinYun:boolean) {
    commit('SET_XIN_YUN', xinYun)
  },
  setTool({ commit }:ActionContext<AppState, unknown>, tool:boolean) {
    commit('SET_TOOL', tool)
  },
  setAnalysis({ commit }:ActionContext<AppState, unknown>, analysis:boolean) {
    commit('SET_ANALYSIS', analysis)
  },
  setIsSuperAdmin({ commit }:ActionContext<AppState, unknown>, isSuperAdmin:boolean) {
    commit('SET_IS_SUPER_ADMIN', isSuperAdmin)
  },
  setRefrnaBeta({ commit }:ActionContext<AppState, unknown>, refrna_beta:boolean) {
    commit('SET_REFRNA_BETA', refrna_beta)
  },
  setNotifyShow({ commit }:ActionContext<AppState, unknown>, notifyShow:boolean) {
    commit('SET_NOTIFY_SHOW', notifyShow)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}