import { defaultSettings } from '@/settings'

export function getPageTitle(pageTitle:any):string {
  const title = defaultSettings.title || '联川生物'
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}

// 防抖
export function debounce(func, wait, immediate = true) {
  let timeout
  return function() {
    const context = this, args = arguments
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(function() {
      timeout = null
      if (!immediate) {
        func.apply(context, args)
      }
    }, wait)
    if (callNow) func.apply(context, args)
  }
}

// 节流
export function throttle(func, wait, options = null) {
  let context, args, result
  let timeout = null
  let previous = 0
  if (!options) options = {}
  const later = function() {
    previous = options.leading === false ? 0 : Date.now()
    timeout = null
    result = func.apply(context, args)
    if (!timeout) context = args = null
  }
  return function() {
    let now = Date.now()
    if (!previous && options.leading === false) previous = now
    let remaining = wait - (now - previous)
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now
      result = func.apply(context, args);
      if (!timeout) context = args = null
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining)
    }
    return result
  }
}