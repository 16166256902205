import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

// 路由列表
export const constantRoutes:Array<RouteRecordRaw> = [
  {
    path: '/home',
    component: () => import('@/views/home/index.vue'),
    meta: {
      title: 'Home'
    }
  },
  {
    path: '',
    component: () => import('@/views/front-page/index.vue'),
    redirect: '/home',
    meta: {
      hidden: true
    },
    children: [
      {
        path: '/tool',
        component: () => import('@/views/front-page/tool/index.vue'),
        meta: {
          title: 'Tools'
        }
      }, {
        path: '/space',
        component: () => import('@/views/front-page/my-center/index.vue'),
        meta: {
          title: 'My Center'
        }
      }
    ]
  },
  {
    path: '/mobile-login',
    component: () => import('@/views/home/mobile/login.vue'),
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/register',
    component: () => import('@/views/home/pc/register.vue'),
    meta: {
      title: 'Register'
    }
  },
  {
    path: '/tool/:id',
    component: () => import('@/views/front-page/tool/iframe'),
    meta: {
      hidden: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/common/404.vue'),
    meta: {
      hidden: true
    }
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes: constantRoutes
})

export default router