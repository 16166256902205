const state = {
  userSidebar: {
    opened: true
  }
}

const sidebarState = () => {
  return { ...state }
}

export type SidebarState = ReturnType<typeof sidebarState>
export default state