import Cookies from 'js-cookie'

const state = {
  sidebar: {
    //默认展不展开功能
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  size: Cookies.get('size') || 'medium',
  isMobile: false,
  xinYun: true,
  tool: true,
  analysis: true,
  refrna_beta: true,
  isSuperAdmin: false,
  notifyShow: true
}

const appState = () => {
  return { ...state }
}

export type AppState = ReturnType<typeof appState>
export default state