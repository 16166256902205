import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress'
import { getPageTitle } from '@/utils'
import { getToken } from '@/utils/auth'
import { getIsAvailability } from '@/api/common'
import { ElMessage } from 'element-plus'


// 路由白名单
const routeWhiteList:Array<string> = ['/home', '/mobile-login', '/tool']

// 设置浏览器顶部进度条
NProgress.configure({
  showSpinner: false
}) // NProgress Configuration

// 路由守卫
router.beforeEach(async (to, from, next) => {
  // 开始进度条
  NProgress.start()
  
  // 设置页面标题
  document.title = getPageTitle(to.meta.title)
  
  // 判断是否含有token，判断是否进入页面，挂载路由
  const hasToken = getToken()
  if (hasToken) {
    getIsAvailability().then(res => {
      if (res.data.code === '0000') {
        const { xinYun, tool, analysis, isAdmin, refrna_beta, notify } = res.data.data
        store.dispatch('app/setXinYun', JSON.parse(xinYun))
        store.dispatch('app/setTool', JSON.parse(tool))
        store.dispatch('app/setAnalysis', JSON.parse(analysis))
        store.dispatch('app/setRefrnaBeta', JSON.parse(refrna_beta))
        store.dispatch('app/setIsSuperAdmin', JSON.parse(isAdmin))
        store.dispatch('app/setNotifyShow', JSON.parse(notify))
      } else {
        store.dispatch('app/setXinYun', true)
        store.dispatch('app/setTool', true)
        store.dispatch('app/setAnalysis', true)
        store.dispatch('app/setIsSuperAdmin', false)
        store.dispatch('app/setRefrnaBeta', true)
        store.dispatch('app/setNotifyShow', true)
      }
    })
    if (store.getters.roles.length === 0) {
      // 判断当前用户是否已拉取完user_info信息
      store.dispatch('user/getInfo').then(res => {
        const roles = res.data.data.roles
        store.dispatch('permission/generateRoutes', { roles }).then(accessRoutes => {
          router.addRoute(accessRoutes)
          next({
            ...to,
            replace: true
          })
        })
      }).catch(err => {
        store.dispatch('user/FedLogOut').then(() => {
          ElMessage.error(err)
          next({ path: '/home' })
        })
      })
    }
    if (to.path === '/' || to.path === '/index') {
      next({ path: '/home' })
    } else {
      next()
    }
    NProgress.done()
  } else {
    // 没有token,进入白名单查找
    if (to.path === '/' || to.path === '/index') {
      next({ path: '/home' })
    } else if (routeWhiteList.indexOf(to.path) > -1) {
      next()
    } else {
      if (store.getters.isMobile) {
        next('/mobile-login?redirect=' + to.fullPath)
      } else {
        next('/home?redirect=' + to.fullPath + '&toLogin=true')
      }
      // location.reload()
    }
    NProgress.done()
  }
})

// 路由结束进行百度统计
router.afterEach((to, from, next) => {
  // setTimeout(() => {
  //   var _hmt:any = _hmt || [] as any[]
  //   (function () {
  //     document.getElementById('baidu_tj') && (Window as any).document.getElementById('baidu_tj').remove();
  //     let hm = document.createElement("script");
  //     hm.src = "https://hm.baidu.com/hm.js?0cc0efe6d429121f54590e7c60e7122c";
  //     hm.id="baidu_tj"
  //     const s = document.getElementsByTagName("script")[0];
  //     s.parentNode.insertBefore(hm, s);
  //   })();
  // }, 0)
  NProgress.done()
})
