import {createApp, getCurrentInstance} from 'vue'
import App from './App.vue'
import router from './router'
import './permission'
import ElementPlus from 'element-plus'
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'
import 'element-plus/dist/index.css'
import './styles/index.scss'
import store from './store'
import '@/utils/rem'
import 'virtual:svg-icons-register'
import SvgIcon from '@/components/svg-icon/index.vue'
import { themeList } from '@/utils/info/theme-info.js'
import GlobalInfo from '@/utils/info/baseUrl'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import Swal from 'sweetalert2'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

const app = createApp(App)

// 路由挂载
app.use(router)
app.provide('$router', router)

// 使用element-plus
app.use(ElementPlus)
app.provide('$message', ElMessage)
app.provide('$messageBox', ElMessageBox)
app.provide('$notify', ElNotification)

// store使用
app.use(store)
app.provide('$store', store)

// 使用svg-icon
app.component('svg-icon', SvgIcon)

// 主题设置全局变量
app.provide('themeList', themeList)
app.provide('GLOBAL', GlobalInfo)

// swiper
app.use(VueAwesomeSwiper)

// swalAlert
app.provide('$swal', Swal)

// 图片查看
app.use(VueViewer, {
  defaultOptions: {
    zIndex: 3000,
    inline: false, // Default: false. Enable inline mode.
    button: true, // Show the button on the top-right of the viewer.
    navbar: true, // Specify the visibility of the navbar.
    title: false, // Specify the visibility and the content of the title.
    toolbar: false, // Specify the visibility and layout of the toolbar its buttons.
    tooltip: true, // Show the tooltip with image ratio (percentage) when zooming in or zooming out.
    movable: true, // Enable to move the image.
    zoomable: true, // Enable to zoom the image.
    rotatable: false, // Enable to rotate the image.
    scalable: true, // Enable to scale the image.
    transition: true, // Enable CSS3 Transition for some special elements.
    fullscreen: false, // Enable to request full screen when play.
    keyboard: true, // Enable keyboard support.
    url: 'src' // Default: 'src'. Define where to get the original image URL for viewing.
  }
})
const $viewerApi = getCurrentInstance()?.appContext.config.globalProperties.$
app.provide('$viewerApi', $viewerApi)

// 加载app
app.mount('#app')