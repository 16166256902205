// 请求路径
const BASE_URL:string = 'https://int.omicstudio.cn/'
// 请求根路径
const BASE_ROOT_URL:string = 'https://int.omicstudio.cn/'
// 上传文件
const UPLOAD_URL:string = 'https://int.omicstudio.cn/prod-api/'
// 视频地址
const VIDEO_URL:string = 'https://int.omicstudio.cn'

export default {
  BASE_URL,
  BASE_ROOT_URL,
  UPLOAD_URL,
  VIDEO_URL
}