const getters:any = {
  theme: state => state.theme.theme,
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  isMobile: state => state.app.isMobile,
  xinYun: state => state.app.xinYun,
  refrna_beta: state => state.app.refrna_beta,
  tool: state => state.app.tool,
  analysis: state => state.app.analysis,
  isSuperAdmin: state => state.app.isSuperAdmin,
  notifyShow: state => state.app.notifyShow,
  permission_routes: state => state.permission.routes,
  vipStatus: state => state.user.vipStatus,
  vipDeadLine: state => state.user.vipDeadLine,
  username: state => state.user.username,
  token: state => state.user.token,
  userid: state => state.user.userid,
  avatar: state => state.user.avatar,
  petName: state => state.user.petName,
  roleKey: state => state.user.roleKey,
  useremail: state => state.user.useremail,
  userIdTime: state => state.user.userIdTime,
  roles: state => state.user.roles,
  isADAccount: state => state.user.isADAccount,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  nickName:state=>state.user.nickName,
  deptID: state => state.user.deptID,
  adUserName: state => state.user.adUserName,
  packUp: state => state.user.packUp,
  userSidebar: state => state.sidebar.userSidebar
}

export default getters
