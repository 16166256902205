import moment from 'moment/moment'
import lunarCalendar from 'js-calendar-converter'
import { solarTermsList } from './info/theme-info.js'

// 判断当前季节主题
export function getSeasonTheme() {
  const currentMonth = parseInt(moment().format('MM'))
  switch (true) {
    case currentMonth >= 3 && currentMonth <= 5:
      return 'theme1'
    case currentMonth >= 6 && currentMonth <= 8:
      return 'theme2'
    case currentMonth >= 9 && currentMonth <= 11:
      return 'theme3'
    case currentMonth <= 2 || currentMonth >= 12:
      return 'theme4'
  }
}

// 判断日期是否为节气并返回节气主题
export function nowIsSolarTermTheme() {
  const dateList = moment().format('YYYY-MM-DD').split('-')
  const y = parseInt(dateList[0]),
    m = parseInt(dateList[1]),
    d = parseInt(dateList[2])
  const lunarInfo = lunarCalendar.solar2lunar(y, m, d)
  if (lunarInfo.isTerm) {
    const term = solarTermsList.filter(item => item.name === lunarInfo.Term)
    return term[0].theme
  } else {
    return null
  }
}

