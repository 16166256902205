import { themeList } from '@/utils/info/theme-info.js'
import state, { ThemeState } from './state/theme'

const mutations = {
  CHANGE_THEME: (state: ThemeState, theme:string) => {
    state.theme = theme
    document.getElementsByTagName('body')[0].style.setProperty('--primary-color', themeList[theme].primaryColor)
    document.getElementsByTagName('body')[0].style.setProperty('--primary-color-r', themeList[theme].primaryColorR)
    document.getElementsByTagName('body')[0].style.setProperty('--primary-color-g', themeList[theme].primaryColorG)
    document.getElementsByTagName('body')[0].style.setProperty('--primary-color-b', themeList[theme].primaryColorB)
    document.getElementsByTagName('body')[0].style.setProperty('--sub-color', themeList[theme].subColor)
    document.getElementsByTagName('body')[0].style.setProperty('--sub-color-r', themeList[theme].subColorR)
    document.getElementsByTagName('body')[0].style.setProperty('--sub-color-g', themeList[theme].subColorG)
    document.getElementsByTagName('body')[0].style.setProperty('--sub-color-b', themeList[theme].subColorB)
  }
}

const actions = {
  changeTheme({ commit }, theme) {
    localStorage.setItem('userTheme', theme)
    commit('CHANGE_THEME', theme)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
