// 春
const theme1 = {
	primaryColor: '#257265',
	primaryColorR: 37,
	primaryColorG: 114,
	primaryColorB: 101,
	subColor: '#a6c728',
	subColorR: 166,
	subColorG: 199,
	subColorB: 40,
	imgUrl: 'theme1/',
	name: '春',
	py: 'CHUN'
}
// 夏
const theme2 = {
	primaryColor: '#0e519b',
	primaryColorR: 14,
	primaryColorG: 81,
	primaryColorB: 155,
	subColor: '#74dbc9',
	subColorR: 116,
	subColorG: 219,
	subColorB: 201,
	imgUrl: 'theme2/',
	name: '夏',
	py: 'XIA'
}
// 秋
const theme3 = {
	primaryColor: '#db5c2a',
	primaryColorR: 209,
	primaryColorG: 77,
	primaryColorB: 11,
	subColor: '#ffd878',
	subColorR: 255,
	subColorG: 216,
	subColorB: 120,
	imgUrl: 'theme3/',
	name: '秋',
	py: 'QIU'
}
// 冬
const theme4 = {
	primaryColor: '#c64946',
	primaryColorR: 197,
	primaryColorG: 57,
	primaryColorB: 48,
	subColor: '#f691a1',
	subColorR: 246,
	subColorG: 145,
	subColorB: 161,
	imgUrl: 'theme4/',
	name: '冬',
	py: 'DONG'
}

// 立春
const theme1_1 = {
	primaryColor: '#57a97d',
	primaryColorR: 87,
	primaryColorG: 169,
	primaryColorB: 125,
	subColor: '#c8e8c8',
	subColorR: 200,
	subColorG: 232,
	subColorB: 200,
	imgUrl: 'theme1/'
}
// 雨水
const theme1_2 = {
	primaryColor: '#599956',
	primaryColorR: 89,
	primaryColorG: 153,
	primaryColorB: 86,
	subColor: '#b8e88e',
	subColorR: 184,
	subColorG: 232,
	subColorB: 142,
	imgUrl: 'theme1/'
}
// 惊蛰
const theme1_3 = {
	primaryColor: '#688a6b',
	primaryColorR: 104,
	primaryColorG: 138,
	primaryColorB: 107,
	subColor: '#f7ead0',
	subColorR: 247,
	subColorG: 234,
	subColorB: 208,
	imgUrl: 'theme1/'
}
// 春分
const theme1_4 = {
	primaryColor: '#5D7D4F',
	primaryColorR: 93,
	primaryColorG: 125,
	primaryColorB: 79,
	subColor: '#c3cbc2',
	subColorR: 195,
	subColorG: 203,
	subColorB: 194,
	imgUrl: 'theme1/'
}
// 清明
const theme1_5 = {
	primaryColor: '#768f60',
	primaryColorR: 118,
	primaryColorG: 143,
	primaryColorB: 96,
	subColor: '#f4d37f',
	subColorR: 244,
	subColorG: 211,
	subColorB: 127,
	imgUrl: 'theme1/'
}
// 谷雨
const theme1_6 = {
	primaryColor: '#58998c',
	primaryColorR: 88,
	primaryColorG: 153,
	primaryColorB: 140,
	subColor: '#a6e2dc',
	subColorR: 166,
	subColorG: 226,
	subColorB: 220,
	imgUrl: 'theme1/'
}

// 立夏
const theme2_1 = {
	primaryColor: '#5899a5',
	primaryColorR: 88,
	primaryColorG: 153,
	primaryColorB: 165,
	subColor: '#e5e2d6',
	subColorR: 229,
	subColorG: 226,
	subColorB: 214,
	imgUrl: 'theme2/'
}
// 小满
const theme2_2 = {
	primaryColor: '#2F7A8E',
	primaryColorR: 47,
	primaryColorG: 122,
	primaryColorB: 142,
	subColor: '#9dbfd6',
	subColorR: 157,
	subColorG: 191,
	subColorB: 214,
	imgUrl: 'theme2/'
}
// 芒种
const theme2_3 = {
	primaryColor: '#4f88a8',
	primaryColorR: 79,
	primaryColorG: 136,
	primaryColorB: 168,
	subColor: '#f9d0c0',
	subColorR: 249,
	subColorG: 208,
	subColorB: 192,
	imgUrl: 'theme2/'
}
// 夏至
const theme2_4 = {
	primaryColor: '#5187af',
	primaryColorR: 81,
	primaryColorG: 135,
	primaryColorB: 175,
	subColor: '#bee9f4',
	subColorR: 190,
	subColorG: 233,
	subColorB: 244,
	imgUrl: 'theme2/'
}
// 小暑
const theme2_5 = {
	primaryColor: '#5a71a3',
	primaryColorR: 90,
	primaryColorG: 113,
	primaryColorB: 163,
	subColor: '#f4c3a2',
	subColorR: 244,
	subColorG: 195,
	subColorB: 162,
	imgUrl: 'theme2/'
}
// 大暑
const theme2_6 = {
	primaryColor: '#575f84',
	primaryColorR: 87,
	primaryColorG: 95,
	primaryColorB: 132,
	subColor: '#d8c0c5',
	subColorR: 216,
	subColorG: 192,
	subColorB: 197,
	imgUrl: 'theme2/'
}

// 立秋
const theme3_1 = {
	primaryColor: '#875054',
	primaryColorR: 135,
	primaryColorG: 80,
	primaryColorB: 84,
	subColor: '#ffbf9c',
	subColorR: 255,
	subColorG: 191,
	subColorB: 156,
	imgUrl: 'theme3/'
}
// 处暑
const theme3_2 = {
	primaryColor: '#8c6465',
	primaryColorR: 140,
	primaryColorG: 100,
	primaryColorB: 101,
	subColor: '#f2dac2',
	subColorR: 242,
	subColorG: 218,
	subColorB: 194,
	imgUrl: 'theme3/'
}
// 白露
const theme3_3 = {
	primaryColor: '#b15944',
	primaryColorR: 177,
	primaryColorG: 89,
	primaryColorB: 68,
	subColor: '#e1c2ca',
	subColorR: 225,
	subColorG: 194,
	subColorB: 202,
	imgUrl: 'theme3/'
}
// 秋分
const theme3_4 = {
	primaryColor: '#af514f',
	primaryColorR: 175,
	primaryColorG: 81,
	primaryColorB: 79,
	subColor: '#ffd39f',
	subColorR: 255,
	subColorG: 211,
	subColorB: 159,
	imgUrl: 'theme3/'
}
// 寒露
const theme3_5 = {
	primaryColor: '#af5e56',
	primaryColorR: 175,
	primaryColorG: 94,
	primaryColorB: 86,
	subColor: '#f4b1a4',
	subColorR: 244,
	subColorG: 177,
	subColorB: 164,
	imgUrl: 'theme3/'
}
// 霜降
const theme3_6 = {
	primaryColor: '#AD5763',
	primaryColorR: 173,
	primaryColorG: 87,
	primaryColorB: 99,
	subColor: '#e0a9b8',
	subColorR: 224,
	subColorG: 169,
	subColorB: 184,
	imgUrl: 'theme3/'
}

// 立冬
const theme4_1 = {
	primaryColor: '#bb5261',
	primaryColorR: 187,
	primaryColorG: 82,
	primaryColorB: 97,
	subColor: '#e8919d',
	subColorR: 232,
	subColorG: 145,
	subColorB: 157,
	imgUrl: 'theme4/'
}
// 小雪
const theme4_2 = {
	primaryColor: '#bf4b6c',
	primaryColorR: 191,
	primaryColorG: 75,
	primaryColorB: 108,
	subColor: '#d5beed',
	subColorR: 213,
	subColorG: 190,
	subColorB: 237,
	imgUrl: 'theme4/'
}
// 大雪
const theme4_3 = {
	primaryColor: '#aa4b72',
	primaryColorR: 170,
	primaryColorG: 75,
	primaryColorB: 114,
	subColor: '#e588c4',
	subColorR: 229,
	subColorG: 136,
	subColorB: 196,
	imgUrl: 'theme4/'
}
// 冬至
const theme4_4 = {
	primaryColor: '#A0538E',
	primaryColorR: 160,
	primaryColorG: 83,
	primaryColorB: 142,
	subColor: '#ed9dba',
	subColorR: 237,
	subColorG: 157,
	subColorB: 186,
	imgUrl: 'theme4/'
}
// 小寒
const theme4_5 = {
	primaryColor: '#845f99',
	primaryColorR: 132,
	primaryColorG: 95,
	primaryColorB: 153,
	subColor: '#e8919d',
	subColorR: 232,
	subColorG: 145,
	subColorB: 157,
	imgUrl: 'theme4/'
}
// 大寒
const theme4_6 = {
	primaryColor: '#625da5',
	primaryColorR: 98,
	primaryColorG: 93,
	primaryColorB: 165,
	subColor: '#efc15b',
	subColorR: 239,
	subColorG: 193,
	subColorB: 91,
	imgUrl: 'theme4/'
}

// 主题
export const themeList = {
	'theme1': theme1,
	'theme1_1': theme1_1,
	'theme1_2': theme1_2,
	'theme1_3': theme1_3,
	'theme1_4': theme1_4,
	'theme1_5': theme1_5,
	'theme1_6': theme1_6,
	'theme2': theme2,
	'theme2_1': theme2_1,
	'theme2_2': theme2_2,
	'theme2_3': theme2_3,
	'theme2_4': theme2_4,
	'theme2_5': theme2_5,
	'theme2_6': theme2_6,
	'theme3': theme3,
	'theme3_1': theme3_1,
	'theme3_2': theme3_2,
	'theme3_3': theme3_3,
	'theme3_4': theme3_4,
	'theme3_5': theme3_5,
	'theme3_6': theme3_6,
	'theme4': theme4,
	'theme4_1': theme4_1,
	'theme4_2': theme4_2,
	'theme4_3': theme4_3,
	'theme4_4': theme4_4,
	'theme4_5': theme4_5,
	'theme4_6': theme4_6
}
// 季节列表
export const seasonList = [
	{
		theme: 'theme1',
		backImg: new URL('../../assets/theme/theme/theme1-back.png', import.meta.url).href,
		top: {
			svg: 'theme1-top',
			name: '绿茶'
		},
		bottom: {
			svg: 'theme1-bottom',
			name: '翠绿'
		}
	},
	{
		theme: 'theme2',
		backImg: new URL('../../assets/theme/theme/theme2-back.png', import.meta.url).href,
		top: {
			svg: 'theme2-top',
			name: '松石'
		},
		bottom: {
			svg: 'theme2-bottom',
			name: '花青'
		}
	},
	{
		theme: 'theme3',
		backImg: new URL('../../assets/theme/theme/theme3-back.png', import.meta.url).href,
		top: {
			svg: 'theme3-top',
			name: '栀子'
		},
		bottom: {
			svg: 'theme3-bottom',
			name: '黄丹'
		}
	},
	{
		theme: 'theme4',
		backImg: new URL('../../assets/theme/theme/theme4-back.png', import.meta.url).href,
		top: {
			svg: 'theme4-top',
			name: '桃红'
		},
		bottom: {
			svg: 'theme4-bottom',
			name: '绛红'
		}
	}
]
// 节气列表
export const solarTermsList = [
	{
		theme: 'theme1_1',
		name: '立春',
		py: 'LI CHUN',
		backImg: new URL('../../assets/theme/theme/theme1_1-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme1_2',
		name: '雨水',
		py: 'YU SHUI',
		backImg: new URL('../../assets/theme/theme/theme1_2-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme1_3',
		name: '惊蛰',
		py: 'JING ZHE',
		backImg: new URL('../../assets/theme/theme/theme1_3-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme1_4',
		name: '春分',
		py: 'CHUN FEN',
		backImg: new URL('../../assets/theme/theme/theme1_4-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme1_5',
		name: '清明',
		py: 'QING MING',
		backImg: new URL('../../assets/theme/theme/theme1_5-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme1_6',
		name: '谷雨',
		py: 'GU YU',
		backImg: new URL('../../assets/theme/theme/theme1_6-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme2_1',
		name: '立夏',
		py: 'LI XIA',
		backImg: new URL('../../assets/theme/theme/theme2_1-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme2_2',
		name: '小满',
		py: 'XIAO MAN',
		backImg: new URL('../../assets/theme/theme/theme2_2-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme2_3',
		name: '芒种',
		py: 'MANG ZHONG',
		backImg: new URL('../../assets/theme/theme/theme2_3-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme2_4',
		name: '夏至',
		py: 'XIA ZHI',
		backImg: new URL('../../assets/theme/theme/theme2_4-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme2_5',
		name: '小暑',
		py: 'XIAO SHU',
		backImg: new URL('../../assets/theme/theme/theme2_5-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme2_6',
		name: '大暑',
		py: 'DA SHU',
		backImg: new URL('../../assets/theme/theme/theme2_6-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme3_1',
		name: '立秋',
		py: 'LI QIU',
		backImg: new URL('../../assets/theme/theme/theme3_1-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme3_2',
		name: '处暑',
		py: 'CHU SHU',
		backImg: new URL('../../assets/theme/theme/theme3_2-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme3_3',
		name: '白露',
		py: 'BAI LU',
		backImg: new URL('../../assets/theme/theme/theme3_3-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme3_4',
		name: '秋分',
		py: 'QIU FEN',
		backImg: new URL('../../assets/theme/theme/theme3_4-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme3_5',
		name: '寒露',
		py: 'HAN LU',
		backImg: new URL('../../assets/theme/theme/theme3_5-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme3_6',
		name: '霜降',
		py: 'SHUANG JIANG',
		backImg: new URL('../../assets/theme/theme/theme3_6-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme4_1',
		name: '立冬',
		py: 'LI DONG',
		backImg: new URL('../../assets/theme/theme/theme4_1-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme4_2',
		name: '小雪',
		py: 'XIAO XUE',
		backImg: new URL('../../assets/theme/theme/theme4_2-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme4_3',
		name: '大雪',
		py: 'DA XUE',
		backImg: new URL('../../assets/theme/theme/theme4_3-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme4_4',
		name: '冬至',
		py: 'DONG ZHI',
		backImg: new URL('../../assets/theme/theme/theme4_4-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme4_5',
		name: '小寒',
		py: 'XIAO HAN',
		backImg: new URL('../../assets/theme/theme/theme4_5-back.png', import.meta.url).href,
		welcomeText: ''
	},
	{
		theme: 'theme4_6',
		name: '大寒',
		py: 'DA HAN',
		backImg: new URL('../../assets/theme/theme/theme4_6-back.png', import.meta.url).href,
		welcomeText: ''
	}
]
