import request from '@/utils/request.js'

// 获取云分析、云工具、星云是否可以使用
export function getIsAvailability() {
  return request({
    url: '/tool/getIsAvailability',
    method: 'get'
  })
}

// 获取路由
export const getRouters = () => {
  return request({
    url: '/getRouters',
    method: 'get'
  })
}