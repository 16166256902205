import request from '@/utils/request.js'

// 判断登录用户是否是Vip
export function getUserVipData(data) {
  return request({
    url: '/userVip/getUserVipState',
    method: 'get',
    params: data
  })
}

// 用户修改主题
export function updateSubject(userId, theme) {
  return request({
    url: '/users/updateSubject',
    method: 'post',
    params: {
      uId: userId,
      subject: theme
    }
  })
}

// 用户获取主题
export function getSubject(userId) {
  return request({
    url: '/users/getSubject',
    method: 'get',
    params: {
      uId: userId
    }
  })
}

// 获取用户信息
export function getUsersInfo(data) {
  return request({
    url: '/users/getInfo',
    method: 'get',
    params:data
  })
}

//获取用户信息ById
export function getUserById(id) {
  return request({
    url: '/flower/getUserById',
    method: 'get',
    params: {
      userId: id
    }
  })
}

// 设置用户全局tips收起展开
export function setPackUp(data) {
  return request({
    url: '/users/packUp',
    method: 'post',
    params: data
  })
}

// 更改密码
export function updatePwd(data) {
  return request({
    url: '/system/user/updatePwd',
    method: 'post',
    params: data
  })
}
