import request from '@/utils/request.js'

// 判断是否为AD域账号
export function isADAccount(data) {
  return request({
    url: '/api/checkEmail',
    method: 'get',
    params: data
  })
}
