const state = {
  routes: [],
  addRoutes: []
}

const permissionState = () => {
  return { ...state }
}

export type PermissionState = ReturnType<typeof permissionState>
export default state