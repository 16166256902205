import Cookies from 'js-cookie'

// 设置过期时间
const halfADay : number = 0.5
const TokenKey : string = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token : string) {
  return Cookies.set(TokenKey, token, {
    expires: halfADay
  })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setCookie(key : string, value : any) {
  return Cookies.set(key, value, { expires: halfADay })
}

export function getCookie(key : string) {
  return Cookies.get(key)
}

export function removeCookie(key : string) {
  return Cookies.remove(key)
}