import request from '@/utils/request.js'

//忘记密码与注册获取验证码
export function getValidatorType(data) {
  return request({
    url: '/lc/cloud/getEmailCode',
    method: 'post',
    data
  })
}

// 用户注册
export function usersRegister(data) {
  return request({
    url: '/lc/cloud/lcRegister',
    method: 'post',
    data
  })
}

// 修改密码
export function changePassword(data) {
  return request({
    url: '/lc/cloud/editPassword',
    method: 'post',
    data
  })
}

// 用户登录
export function usersLogin(data) {
  return request({
    url: '/lc/cloud/login',
    method: 'post',
    data
  })
}

// 用户退出
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}