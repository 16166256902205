<template>
  <router-view />
  <el-dialog
    :model-value="data.alertVisible"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    center
    append-to-body
    class="alert-dialog"
  >
    <div class="icon">
      <el-icon>
        <warning />
      </el-icon>
    </div>
    <p>由于您长时间未操作或切换了其他账号，</p>
    <p>故{{ userEmail }}账号已退出登录。</p>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="data.showReLogin" type="primary" :size="isMobile ? 'small' : 'default'" @click="toLogin">
        Login
      </el-button>
    </span>
  </el-dialog>
</template>

<script setup lang="ts">
import {computed, inject, nextTick, onMounted, reactive} from 'vue'
import { setRem } from '@/utils/rem'
import {Warning} from '@element-plus/icons'
import { getCookie } from '@/utils/auth'
import { nowIsSolarTermTheme, getSeasonTheme } from '@/utils/theme'

/*inject*/
const $store = inject('$store')
const $router = inject('$router')

/*data*/
const data = reactive({
  alertVisible: false,
  showReLogin: false
})

/*computed*/
const userEmail = computed(() => {
  return $store.getters.useremail
})
const isMobile = computed(() => {
  return $store.getters.isMobile
})
const isLogin = computed(() => {
  return $store.getters.username != ''
})

/*created*/
nextTick(() => {
  document.addEventListener('visibilitychange', tipDialog)
})

/*mounted*/
onMounted(() => {
  init()
  document.body.clientWidth < 768 ? $store.dispatch('app/setIsMobile', true) : $store.dispatch('app/setIsMobile', false)
  window.onresize = () => {
    setRem()
    document.body.clientWidth < 768 ? $store.dispatch('app/setIsMobile', true) : $store.dispatch('app/setIsMobile', false)
  }
})

/*methods*/
const init = () => {
  // 未登录情况下展示当前季节主题
  if (!isLogin.value) {
    // 判断当前是否为节气主题
    let defaultTheme = 'theme1'
    if (nowIsSolarTermTheme() !== null) {
      defaultTheme = nowIsSolarTermTheme()
    } else {
      // 判断季节主题
      defaultTheme = getSeasonTheme()
    }
    $store.dispatch('theme/changeTheme', defaultTheme)
  }
}
const tipDialog = () => {
  const storeUserIdTime = $store.getters.userIdTime
  const cookieUserId = getCookie('CLOUD-USER-ID')
  const documentVisible = document.visibilityState
  if (documentVisible === 'visible' && storeUserIdTime !== cookieUserId && storeUserIdTime) {
    data.alertVisible = true
    userEmail.value = $store.getters.useremail
    data.showReLogin = !cookieUserId
  }
}
const toLogin = () => {
  $store.dispatch('user/LogOut').then(() => {
    if (isMobile.value) {
      $router.push('/mobile-login')
    } else {
      $router.push({
        path: '/home',
        query: {
          toLogin: true
        }
      })
      location.reload()
    }
    const storage = window.localStorage
    storage.clear()
    sessionStorage.removeItem('vipStatusData')
  })
}
</script>
<style lang="scss" scoped>
:deep(.alert-dialog) {
  width: 31.25rem;
  margin-top: 0!important;
  top: 50%;
  transform: translateY(-50%);
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 2rem;
    text-align: center;
    .icon {
      color: #E6A23C;
      font-size: 3rem;
    }
    p {
      margin-top: 1.25rem;
      font-weight: bolder;
      color: #000;
      font-size: 1.25rem;
    }
  }
  .dialog-footer {
    .el-button:hover {
      border-color: $primaryColor;
      background-color: $subColor;
      color: $primaryColor;
    }
  }
}
</style>