import state, { UserState } from './state/user'
import { ActionContext } from 'vuex'
import { setToken, removeToken, setCookie, removeCookie } from '@/utils/auth'
import { usersLogin, logout } from '@/api/login'
import { getUsersInfo, getUserById, getSubject, setPackUp } from '@/api/user'
import { isADAccount } from '@/api/synergy'
import store from '@/store'
import { getSeasonTheme, nowIsSolarTermTheme } from '@/utils/theme'

const mutations = {
  SET_TOKEN: (state:UserState, token:string) => {
    state.token = token
  },
  SET_INTRODUCTION: (state:UserState, introduction:string) => {
    state.introduction = introduction
  },
  SET_NAME: (state:UserState, name:string) => {
    state.name = name
  },
  SET_AVATAR: (state:UserState, avatar:string) => {
    state.avatar = avatar
  },
  SET_ROLES: (state:UserState, roles:any) => {
    state.roles = roles
  },
  SET_USERNAME: (state:UserState, username:string) => {
    state.username = username
  },
  SET_USERID: (state:UserState, userid:any) => {
    state.userid = userid
  },
  SET_USEREMAIL: (state:UserState, useremail:string) => {
    state.useremail = useremail
  },
  SET_NICKNAME: (state:UserState, nickName:string) => {
    state.nickName = nickName
  },
  SET_ROLEKEY: (state:UserState, roleKey:any) => {
    state.roleKey = roleKey
  },
  SET_DEPTID: (state:UserState, deptID:any) => {
    state.deptID = deptID
  },
  SET_PETNAME: (state:UserState, petName:string) => {
    state.petName = petName
  },
  SET_USER_ID_TIME: (state:UserState, userIdTime:string) => {
    state.userIdTime = userIdTime
  },
  SET_IS_AD_ACCOUNT: (state:UserState, isADAccount:number) => {
    state.isADAccount = isADAccount
  },
  SET_AD_USERNAME: (state:UserState, adUserName:string) => {
    state.adUserName = adUserName
  },
  SET_PACK_UP: (state:UserState, packUp:number) => {
    state.packUp = packUp
  },
  SET_VIP_STATUS: (state:UserState, vipStatus:number) => {
    state.vipStatus = vipStatus
  },
  SET_VIP_DEAD_LINE: (state:UserState, vipDeadLine:string) => {
    state.vipDeadLine = vipDeadLine
  }
}

const actions = {
  // 设置VIP状态
  changeVipStatus({ commit }:ActionContext<UserState, unknown>, vipStatus:number) {
    commit('SET_VIP_STATUS', vipStatus)
  },
  
  // 设置VIP到期时间
  changeVipDeadLine({ commit }:ActionContext<UserState, unknown>, vipDeadLine:string) {
    commit('SET_VIP_DEAD_LINE', vipDeadLine)
  },

  // 退出登录
  LogOut({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout().then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_USERNAME', '')
        commit('SET_USERID', '')
        commit('SET_USEREMAIL', '')
        commit('SET_NICKNAME', '')
        commit('SET_ROLEKEY', '')
        commit('SET_DEPTID', '')
        commit('SET_PETNAME', '')
        commit('SET_AVATAR', '')
        commit('SET_USER_ID_TIME', '')
        commit('SET_PACK_UP', 0)
        commit('SET_VIP_STATUS', 0)
        commit('SET_VIP_DEAD_LINE', '')
        
        removeToken()
        removeCookie('CLOUD-USER-ID')
        localStorage.removeItem('resourceWarning')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  // 登录
  Login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      // 登录前先清空原先的token和ID时间
      removeCookie('CLOUD-USER-ID')
      commit('SET_USER_ID_TIME', '')
      usersLogin(userInfo).then(res => {
        if (res.data.code === '0000') {
          const { userId, token } = res.data.data
          setToken(token)
          commit('SET_TOKEN', token)
          const str = userId + ''
          setCookie('CLOUD-USER-ID', str)
          commit('SET_USER_ID_TIME', str)
        }
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getUsersInfo({}).then(res => {
        let roles = res.data.data.roles
        roles.forEach((item, index) => {
          let rolekey = []
          rolekey.push(item.roleKey)
          commit('SET_ROLEKEY', rolekey)
        })
        commit('SET_USERID', res.data.data.id)
        commit('SET_USEREMAIL', res.data.data.email)
        commit('SET_DEPTID', res.data.data.deptId)
        
        isADAccount({ email: res.data.data.email }).then(isAD => {
          if (isAD.data.code === '0000') {
            commit('SET_IS_AD_ACCOUNT', isAD.data.data.isAD)
            commit('SET_AD_USERNAME', isAD.data.data.username)
          }
        })
        
        getUserById(res.data.data.id).then(res1 => {
          // 用户名等信息实时变更获取
          commit('SET_USERNAME', res1.data.data.userName)
          commit('SET_NICKNAME', res1.data.data.nickName)
          commit('SET_PETNAME', res1.data.data.petName)
          commit('SET_AVATAR', res1.data.data.avatar)
          commit('SET_PACK_UP', res1.data.data.packUp)
          commit('SET_VIP_STATUS', res1.data.data.vipState)
          commit('SET_VIP_DEAD_LINE', res1.data.data.deadline || '')
        })
        
        if (roles && roles.length > 0) { // 验证返回的roles是否是一个非空数组
          commit('SET_ROLES', roles)
        } else {
          commit('SET_ROLES', ['ROLE_DEFAULT'])
        }
        
        // 设置主题
        getSubject(res.data.data.id).then(res2 => {
          if (res2.data.code === '0000') {
            if (res2.data.data) {
              store.commit('theme/CHANGE_THEME', res2.data.data)
            } else {
              // 判断当前是否为节气主题
              let defaultTheme = 'theme1'
              if (nowIsSolarTermTheme() !== null) {
                defaultTheme = nowIsSolarTermTheme()
              } else {
                // 判断季节主题
                defaultTheme = getSeasonTheme()
              }
              store.commit('theme/CHANGE_THEME', defaultTheme)
            }
          }
        })
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  // permission登出
  FedLogOut({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_USER_ID_TIME', '')
      removeCookie('CLOUD-USER-ID')
      removeToken()
      resolve()
    })
  },
  
  // 设置petName
  changePetName({ commit }, petName) {
    commit('SET_PETNAME', petName)
  },
  
  // 设置petName
  changeAvatar({ commit }, avatar) {
    commit('SET_AVATAR', avatar)
  },
  
  // 设置packUp
  changePackUp({ commit }, packUp) {
    if (store.getters.username != '') {
      setPackUp({ status: packUp }).then()
    }
    commit('SET_PACK_UP', packUp)
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}
