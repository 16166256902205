// rem等比适配配置文件
// 基准大小
const mobileBaseSize:number = 100
const pcBaseSize:number = 16
// 设置 rem 函数
export function setRem () {
  if (document.body.clientWidth < 768) {
    // 当前页面宽度相对于 750宽的缩放比例，可根据自己需要修改。
    const scale = document.documentElement.clientWidth / 750
    // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
    document.documentElement.style.fontSize = mobileBaseSize * Math.min(scale, 2) + 'px'
  } else {
    // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
    const scale = document.documentElement.clientWidth / 1920
    // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
    document.documentElement.style.fontSize = pcBaseSize * Math.min(scale, 2) + 'px'
  }
}

// rem转px
export function remToPx(rem) {
  return document.body.clientWidth < 768 ? rem * 100 : rem * 16;
}
// px转rem
export function pxToRem(px) {
  return document.body.clientWidth < 768 ? px / 100 : px / 16;
}

// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}
