import axios from 'axios'
import {getToken} from '@/utils/auth'
import errorCode from '@/utils/info/error-code'
import { ElMessage  } from 'element-plus'
import store from '@/store'

// create an axios instance
const service = axios.create({
  // 业务测试接口
  baseURL: 'https://int.omicstudio.cn/prod-api'
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 配置是否需要token
    const header = config.headers || {}
    const isToken = header.isToken === false
    if (getToken() && !isToken && config.url.indexOf('/lc/cloud')<0) {
      //如果存在token，则每个请求都要携带token
      config.headers['Authorization'] = getToken()
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || "0000"
    // 获取错误信息
    const msg = errorCode[code] || res.msg || errorCode['default']
    if (code === "401") {
      store.dispatch('user/LogOut').then(() => {
        location.href = store.getters.isMobile ? '/mobile-login' : '/home?toLogin=true';
      })
    } else if (code === "500") {
      ElMessage ({
        message: msg,
        type: 'error'
      })
      return Promise.reject(new Error(msg))
    } else {
      return  res
    }
  },
  (error) => {
    let { message } = error
    if (message === "Network Error") {
      message = "Network Error"
    }
    else if (message.includes("timeout")) {
      message = "The system interface request timed out"
    }
    else if (message.includes("Request failed with status code")) {
      message = "The system interface " + message.substr(message.length - 3)
    }
    ElMessage ({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service